// Table sorter
$(document)
		.ready(
				function() {

					// Permet de fermer les popover quand on click à côté
					$('body').on('click', function (e) {
					    $('[data-toggle=popover]').each(function () {
					        // hide any open popovers when the anywhere else in the body is clicked
					        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
					            $(this).popover('hide');
					        }
					    });
					});

					// Permet de faire le collapse avec le switch dans la
					// sidebar
					// $("body").on("click", "#checkbox-temps-reel input",
					// function(e) {
					// if (this.checked) {
					// $('#collapseRealTime').collapse('hide');
					// } else {
					// $('#collapseRealTime').collapse('show');
					// }
					// });

					$('.input-daterange').datepicker({
						format : "dd/mm/yyyy",
						todayHighlight : true,
						immediateUpdates : true
					});

					$("body").on("click",
							"#packet-explorer-container .list-group-item",
							function(e) {
								e.preventDefault();
							});

					// $('.scroll-top').click(function() {
					// $('#scroll-packet-list').animate({
					// scrollTop: '0px'
					// }, 1500);
					// return false;
					// });
					setInterval(afficherDateUTC, 1000);
					function afficherDateUTC() {
						var d = new Date();
						var dateUTC = ("0" + d.getUTCDate()).slice(-2) + "/"
								+ ("0" + (d.getUTCMonth() + 1)).slice(-2) + "/"
								+ d.getUTCFullYear() + " "
								+ ("0" + d.getUTCHours()).slice(-2) + ":"
								+ ("0" + d.getUTCMinutes()).slice(-2) + ":"
								+ ("0" + d.getUTCSeconds()).slice(-2) + " UTC";
						$('#date-UTC').html(dateUTC);
					}
					window.onload = function() {
						afficherDateUTC();
						setInterval(afficherDateUTC, 1000);
					}

					$('#full-screen-button')
							.on(
									"click",
									function() {
										var el = document.documentElement, rfs = el.requestFullscreen
												|| el.webkitRequestFullScreen
												|| el.mozRequestFullScreen
												|| el.msRequestFullscreen;
										toggleFullScreen(el);
									});

					function isFullScreen() {
						return (document.fullScreenElement && document.fullScreenElement !== null)
								|| document.mozFullScreen
								|| document.webkitIsFullScreen;
					}

					function requestFullScreen(element) {
						if (element.requestFullscreen)
							element.requestFullscreen();
						else if (element.msRequestFullscreen)
							element.msRequestFullscreen();
						else if (element.mozRequestFullScreen)
							element.mozRequestFullScreen();
						else if (element.webkitRequestFullscreen)
							element.webkitRequestFullscreen();
					}

					function exitFullScreen() {
						if (document.exitFullscreen)
							document.exitFullscreen();
						else if (document.msExitFullscreen)
							document.msExitFullscreen();
						else if (document.mozCancelFullScreen)
							document.mozCancelFullScreen();
						else if (document.webkitExitFullscreen)
							document.webkitExitFullscreen();
					}

					function toggleFullScreen(element) {
						if (isFullScreen())
							exitFullScreen();
						else
							requestFullScreen(element
									|| document.documentElement);
					}

					$('.input-daterange').datepicker({
						format : "dd/mm/yyyy",
						todayHighlight : true,
						immediateUpdates : true
					});

					$('[data-toggle="popover"]').popover();

					// $('body').tooltip({
					// selector: '[rel=tooltip]'
					// });
					$('[data-toggle="tooltip"]').tooltip();

					// $('.scroll-top').click(function() {
					// $('#scroll-packet-list').animate({
					// scrollTop: '0px'
					// }, 1500);
					// return false;
					// });

					$('#full-screen-button')
							.on(
									"click",
									function() {
										var el = document.documentElement, rfs = el.requestFullscreen
												|| el.webkitRequestFullScreen
												|| el.mozRequestFullScreen
												|| el.msRequestFullscreen;
										toggleFullScreen(el);
									});

					$("#searchbarTc > input").on('input', function() {
						onTcSelectFocusIn();
					});

					// Bloque la fermeture de la liste si on reclique sur le
					// champ recherche
					$('#searchbarTc').on('hide.bs.dropdown', function(e) {
						if ($("#searchbarTc > input").is(":focus")) {
							return false;
						}
					})

					function isFullScreen() {
						return (document.fullScreenElement && document.fullScreenElement !== null)
								|| document.mozFullScreen
								|| document.webkitIsFullScreen;
					}

					function requestFullScreen(element) {
						if (element.requestFullscreen)
							element.requestFullscreen();
						else if (element.msRequestFullscreen)
							element.msRequestFullscreen();
						else if (element.mozRequestFullScreen)
							element.mozRequestFullScreen();
						else if (element.webkitRequestFullscreen)
							element.webkitRequestFullscreen();
					}

					function exitFullScreen() {
						if (document.exitFullscreen)
							document.exitFullscreen();
						else if (document.msExitFullscreen)
							document.msExitFullscreen();
						else if (document.mozCancelFullScreen)
							document.mozCancelFullScreen();
						else if (document.webkitExitFullscreen)
							document.webkitExitFullscreen();
					}

					function toggleFullScreen(element) {
						if (isFullScreen())
							exitFullScreen();
						else
							requestFullScreen(element
									|| document.documentElement);
					}

					function initGridStack() {

					}

				});

function onTcSelectFocusIn() {
	if (($("#dropdown-list-tc").children().length / 2) == 1) {
		$("#dropdown-list-tc").children().first().focus().toggleClass('active');
	} else {
		$("#dropdown-list-tc").children().blur().removeClass('active');
	}
}

//
// function setStartEndDate() {
// var date_start_input = $('input#dateStartFilter');
// var date_end_input = $('input#dateEndFilter');
//
// date_start_input.datepicker().on('changeDate', function(e) {
// date_end_input.datepicker("setStartDate", e.date);
// });
// date_end_input.datepicker().on('changeDate', function(e) {
// date_start_input.datepicker("setEndDate", e.date);
// });
//
// }
